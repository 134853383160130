<template>

  <div>
    <!-- Filters -->
    <books-list-filters
        :category-filter.sync="categoryFilter"
    />

    <book-list-add-new
        :is-add-new-book-sidebar-active.sync="isAddNewBookSidebarActive"
        :book-id="bookID"
        @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Показ</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>строка</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Поиск..."
              />
              <b-button
                  variant="primary"
                  @click="addBook(0)"
              >
                <span class="text-nowrap">Добавить</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refBookListTable"
          class="position-relative"
          :items="fetchBooks"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Записи не найдены"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Book -->
        <template #cell(photo)="data" style="width: 30px;">
          <b-media vertical-align="center">
            <template #aside>
              <b-img
                width="80"
                :src="data.item.photo ? 'https://edu.startupchoikhona.tj/backend'+data.item.photo : ''"
              />
            </template>
          </b-media>
        </template>

        <!-- Column: Book -->
        <template #cell(name)="data">
          <div class="d-inline-flex align-items-center">
            <a
                :href="data.item.file ? `https://edu.startupchoikhona.tj/backend${data.item.file}` : ''"
                class="font-weight-bold d-block text-nowrap"
                download
            >
              {{ data.item.name }}
            </a>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <span @click="show(data.item.file ? `https://edu.startupchoikhona.tj/backend${data.item.file}` : '')">
            <feather-icon icon="EyeIcon" class="text-secondary mr-1 font-medium-5"/>
          </span>

          <a
              v-if="data.item.file"
              :href="data.item.file ? `https://edu.startupchoikhona.tj/backend${data.item.file}` : ''"
              class=""
              download
          >
            <feather-icon icon="DownloadIcon" class="text-info mr-1 font-medium-5"/>
          </a>
          <span @click="addBook(data.item.id)">
            <feather-icon icon="EditIcon" class="text-primary mr-1 font-medium-5"/>
          </span>
          <span @click="deleteBook(data.item.id)">
            <feather-icon icon="Trash2Icon" class="text-danger mr-1 font-medium-5"/>
          </span>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Показано {{ dataMeta.from }} до {{ dataMeta.to }} из {{ dataMeta.of }} строк</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalBooks"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal
        id="modal-lg"
        dialog-class="w-100 h-100"
        content-class="w-100 h-100"
        size="xl"
        centered
        ok-only
        @click="modalShow = !modalShow"
        ok-title="Закрыть"
        v-model="modalShow"
    >
      <iframe :src="showPath" frameborder="0" class="w-100 h-100"></iframe>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useBooksList from './useBooksList'
import BooksListFilters from './BooksListFilters.vue'
import BookListAddNew from './BookListAddNew.vue'

export default {
  components: {
    BModal,
    BookListAddNew,
    BooksListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,

    vSelect,
  },
  data() {
    return {
      modalShow: false,
      showPath: '',
    }
  },
  methods: {
    addBook(idBook) {
      this.bookID = idBook
      this.isAddNewBookSidebarActive = true
    },
    show(path){
      this.modalShow = true
      this.showPath = path
    },
  },
  setup() {
    const isAddNewBookSidebarActive = ref(false)
    const bookID = ref(0)


    const {
      fetchBooks,
      deleteBook,
      categoryFilter,
      tableColumns,
      perPage,
      currentPage,
      totalBooks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBookListTable,
      refetchData,

    } = useBooksList()

    return {

      fetchBooks,
      deleteBook,
      categoryFilter,
      tableColumns,
      perPage,
      currentPage,
      totalBooks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBookListTable,
      refetchData,

      // Filter
      avatarText,

      isAddNewBookSidebarActive,
      bookID,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
